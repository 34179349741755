@import '../../global/style/variables';
.teaser {
  margin-bottom: $spacing;
  &--no-margin {
    margin-bottom: 0;
  }
  &--bold {
    font-weight: bolder;
    margin-top: 10px;
  }
}
