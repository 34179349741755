@import "../../global/style/variables.scss"

.label-radiobutton
    display: flex
    align-items: center
    gap: $spacing / 1.5
    > label
        &.error
            color: var(--error-color)
    > img
        margin-right: $spacing / 4
