@import "../../global/style/variables.scss"

.hint-text
    display: flex
    align-items: flex-start
    > div

        padding: $spacing / 2
        &.info
            color: var(--primary-color)
            background-color: var(--primary-light-color)
        &.error
            color: var(--error-color)
            background-color: var(--error-light-color)
