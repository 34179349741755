@import "../../global/style/variables"

//colors
$color_matisse_approx: var(--primary-color)
$color_boulder_approx: var(--grey-color)
$black_15: rgba(0, 0, 0, 0.15)
$white: var(--white-color)
$color_martini_approx: var(--ford-gray-color)
$color_sunset_orange_approx: var(--pumping-spice-color)
$color_sunset_orange_20_approx: rgba(246, 76, 71, 0.2)

%extend_1
  max-width: 666px
  box-shadow: 0 5px 25px 0 $black_15
  background-color: $white
  display: block
  margin: 0 $spacing 0px $spacing
  padding: $spacing 0

%extend_2
  color: $color_matisse_approx

%extend_3
  opacity: 0.95
  box-shadow: inset 0 -1px 0 0 $color_martini_approx
  background-color: rgba(255, 255, 255, 0)
  margin: 0 auto

%extend_4
  color: $color_boulder_approx
  margin-top: $spacing
  text-align: left
  margin-left: 0

%extend_5
  color: $color_boulder_approx
  margin: 0
  position: relative
  top: -10px
  left: 10px
  display: block
  text-align: left

.result-subcontainer
  justify-content: center

div.result-number
  width: 38%
  position: relative
  left: 15px

div.result-headline
  width: 25%

.custom-svg-logo
  filter: brightness(0) invert(1)

.expert-search-dialog
  &__form-field-wrapper
    width: 100%
    margin: 0 auto
    margin-bottom: $spacing

    @include breakpoint($small-tablet-and-up)
      width: 336px

    .form-input-wrapper
      width: 100%!important
      margin-bottom: $spacing
      max-width: 336px

  &__zip-form-wrapper
    width: 336px
    padding: unset
    margin: auto auto 20px auto
    @include breakpoint($small-tablet-and-up)
      padding: 0 $spacing

.expert-page-content-search
  margin-bottom: $spacing
  &__bag
    margin-bottom: $spacing
    margin-top: $spacing * 2
.cmp-image
  margin: 0 20px

.expert-page-content-stage
  width: 100%
  margin: 0 auto
  padding: 0
  position: relative
  top: -22px

.expert-page-content-stage-text
  max-width: 1070px
  position: relative
  top: 0
  height: auto
  margin: 0 auto 0px
  padding: 0 $spacing

  .bottom-text-wrapper
    margin-bottom: $spacing

.it-hilfe
  display: flex
  flex-direction: column
  margin-bottom: $spacing

.schedule
  display: flex
  width: 200px
  justify-content: space-between

.sub-schedule
  font: var(--special-text)

.expert-page-content-sites
  display: block
  margin: 0 auto 0px
  padding: 0
  max-width: 1070px

.expert-search-sub-title
  margin-left: $spacing

.search-pre-section-card-grid
  justify-content: flex-start
  padding: 0px 10px
  margin-bottom: $spacing

@media(max-width: 1086px)
  .search-pre-section-card-grid
    justify-content: center

.expert-page-content-search
  display: block
  visibility: visible

.expert-page-content-search-box
  @extend %extend_1
  text-align: center
  h4
    @extend %extend_2
    text-align: center
    padding: 0 $spacing

.expert-page-content-result-box
  @extend %extend_1
  height: auto

.result-subcontainer
  height: 100px
  border-top: 1px solid $color_martini_approx
  clear: both
  margin: 0 0 $spacing 0

  &:nth-child(1)
    border: none

  @include breakpoint ($bp-small-tablet)
    margin: 0 0 80px 0

div
  &.city-search
    @extend %extend_3

  &.result-headline
    width: 33%
    text-align: left
    margin-top: $spacing

  &.result-number
    width: 49%
    h6
      @extend %extend_4
      img
        padding: 4px 12px

    .notice
      @extend %extend_5
      font-size: 14px

    div
      text-align: left
      color: $color_boulder_approx
      position: relative
      font-size: 18px
      img
        width: 18px
        height: 18px
        padding: 0 10px 7px 0

  &.result-search-query
    width: 49%
    h6
      @extend %extend_4

    .notice
      @extend %extend_5

  &.result-subcontainer div.result-number div.email-modal-open div
    &.email-icon
      left: -4px

    &.icon-label
      position: absolute
      top: -8px
      left: 34px

label
  &#plz-search-label

  &#city-search-label

.search-button-container
  margin-top: 40px

.email-modal-open
  cursor: pointer
  top: 15px
  left: 10px

.expert-search-results
  position: relative
  left: 20px
  width: calc(100% - 40px)
  &__marker-text
    padding: 0 $spacing $spacing $spacing
    font: var(--fine-print)
  &__sales-office-text
    font: var(--special-text)

.expert-page-error-message
  width: calc(100% - 60px)
  margin: 0 auto
  color: $color_sunset_orange_approx
  background-color: $color_sunset_orange_20_approx
  padding: 15px 20px

@media(min-width: 768px) and (max-width: 1024px)

@media(min-width: 570px) and (max-width: 767px)
  .expert-page-content-stage-text

  .expert-page-content-sites

    .card
      width: auto
      margin: 10px 10px 10px 20px

  .expert-page-content-search-box
    height: auto

  .expert-page-content-result-box
    width: calc(100% - 40px)
    margin: 0 14px
    height: auto
    button
      margin: 0 auto 40px

    .result-subcontainer .result-headline
      text-align: center

  div.result-number
    padding: 0 30px
    h6
      margin-left: 0

    div
      left: 10px

@media(min-width: 220px) and (max-width: 569px)
  .expert-page-content
    width: 100%
    bottom: 0 !important
    top: 0
    position: relative

  .expert-page-content-stage
    top: 0
    margin: 0 auto 40px
    padding: 0 0 40px 0
    position: relative

  .expert-page-content-sites
    button
      margin: 20px auto
      .card
        margin: 0 !important

  .expert-page-content-search-box
    width: calc(100% - 28px)
    height: auto
    margin: 0 14px
    padding: 0
    box-shadow: none
    h4
      padding: 0

    div.mdl-textfield
      max-width: 336px
      width: 100%

  .expert-page-content-result-box
    width: calc(100% - 28px)
    height: auto
    margin: 0 14px
    padding: 0
    box-shadow: none
    h2
      font-size: 24px

  div
    &.city-search
      width: 100% !important
      padding: 0 !important
      margin: 0 !important

    &.result-number
      .notice
        left: 0

      h6
        margin-left: 0

      div
        left: 0

    &.result-headline
      margin: 0

  .result-subcontainer
    height: auto
    .result-headline
      width: 100%
      text-align: left
      height: auto

    .result-search-query
      width: 100%
      text-align: left
      height: auto
      h6
        margin: 0

    div.result-number
      width: 100%
      text-align: left
      height: auto
      h6
        margin: 0
        img
          padding: 4px 12px 4px 0

      .notice
        left: 0
        top: 0

      div
        left: 0

  .expert-search-results
    position: relative
    left: 0
    max-width: 346px
    margin: 30px auto
    width: calc(100% - 14px)

  .search-input.plz-search
    width: 100% !important
    padding: 0 !important
    margin: 0 !important

  #plz-search.mdl-textfield__input
    width: 100%

.search-input.plz-search
  @extend %extend_3

input#plz-search:placeholder-shown:not(:focus) + label

select#city-search
  &:not(:placeholder-shown):not(:focus) + label

  &:placeholder-shown:not(:focus) + label
