.themed-switch
    &__switch
        position: relative
        display: inline-block
        width: 40px
        height: 20px

    &__switch input
        opacity: 0
        width: 0
        height: 0

    &__switch-slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: var(--grey-color)
        transition: .4s
        border-radius: 10px

    &__switch-slider:before
        position: absolute
        content: ""
        height: 16px
        width: 16px
        left: 2px
        bottom: 2px
        background-color: var(--white-color)
        transition: .4s
        border-radius: 50%

input:checked + .themed-switch__switch-slider
    background-color: var(--primary-color)

input:checked + .themed-switch__switch-slider:before
    transform: translateX(20px)
