@import "../../global/style/variables.scss"

.homepage-card-content-item-container
    display: flex
    line-height: 24px
    &:nth-child(n)
        margin-bottom: 16px
    &:nth-last-child(1)
        margin-bottom: 0px
    .homepage-card-content-item-icon
        color: var(--white-color)
        font-size: 20px
        margin-left: -6px

    .homepage-card-content-item-number
        min-width: 20px
        max-height: 20px
        padding-top: 2px
        padding-left: 4px
        padding-right: 4px
        background-color: var(--home-page-color)
        margin-left: 6px
        border-radius: 20px
        display: flex
        align-items: center
        justify-content: center
        font-weight: bold

    &.AVA
        .homepage-card-content-item-icon
            font-size: 22px
            margin-left: -8px
            margin-top: 2px

        .homepage-card-content-item-number
            min-width: 25px
            max-height: 25px
            padding-top: 0px
            padding-bottom: 1px
            padding-left: 4px
            padding-right: 4px