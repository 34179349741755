@import "../../global/style/variables"

.events-card
    &__loading-indicator
        margin: 30px 0

    &__kalendar
        fill: var(--primary-color)
        margin-right: -5px
        margin-top: -5px
        margin-bottom: -5px

    &__btn
        margin: 0

    &__teaser-container
        display: flex
        justify-content: space-between
        align-items: center

    &__teaser-title
        font: var(--sub-title)
        color: var(--primary-color)

    &__content-container
        margin-bottom: $spacing

.outer-box
    &.events-card__outer-box
        margin: $spacing / 2 auto $spacing
        min-height: $card-width
        width: calc(#{$card-width} * 3 + (#{$spacing} / 1.5 * 2)* 2) !important
        @include breakpoint(max-width  1090px)
            width: calc(100% - #{$spacing}) !important

        .events-card__btn
            margin: 0
            color: var(--card-link-color)
            &:hover
                color: #fff