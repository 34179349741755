@import '../../global/style/variables'

.data-policy
    max-width: 1070px
    margin: 0 auto $spacing
    padding: 0 $spacing

    li
        padding: $spacing / 4 0
    &__down-section-wrapper
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
    &__switch-section-wrapper
        display: flex
        flex-direction: column
        > div:nth-child(2n)
            margin-top: $spacing
    &__save-button-wrapper
        display: flex
        margin-top: $spacing
    &__response-message
        margin-top: $spacing
        font: var(--error-message-text)
    &__switch-section-intro-text
        margin: $spacing 0
    &__switch-section-extra-text
        margin-top: $spacing

@media (max-width: $bp-small-tablet)
    .data-policy
        &__save-button-wrapper
            justify-content: center
