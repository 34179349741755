@import '../../../../global/style/variables';

.networkConnectionSection {
  .networkConnectionSectionContactPersonContainer {
    margin-bottom: $spacing;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
  }

  .networkConnectionSectionContactPersonItem {
    &:nth-child(4n) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }

    &:nth-child(4n + 2) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }

    &:nth-child(4n + 3) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }
  }

  .borderNone {
    border: none !important;
  }

  // responsiveness
  @media only screen and (max-width: $bp-small-tablet) {
    .networkConnectionSectionContactPersonContainer {
      grid-template-columns: repeat(2, 1fr);
    }

    .networkConnectionSectionContactPersonItem {
      &:nth-child(4n + 1) {
        border-left: none;
        padding-left: 0px;
      }

      &:nth-child(4n + 2) {
        border-left: none;
        padding-left: 0px;
      }

      &:nth-child(4n + 3) {
        border-left: none;
        padding-left: 0px;
      }

      &:nth-child(2n) {
        border-left: 2px solid rgb(242, 242, 242);
        padding-left: 5px;
      }
    }
  }
}
