@import "../multiThemingStyling/multiClientGlobalVariables.scss"

.base-tenant-style
    color: var(--grey-color)
    font: var(--regular-text)

    // base colors for all tenants
    --grey-color: #767676
    --light-silver-color: #d8d8d8
    --pumping-spice-color: #f64c47
    --black-color: #000
    --white-color: #fff
    --quick-silver-color: #9b9b9b
    --mercury-color: #e8e8e8
    --ford-gray-color: #979797
    --action-button-active-color: #e6e6e6
    --error-color: #da251d
    --error-light-color: #DA251D0D
    --link-color: rgb(91, 197, 242)
    --disabled-button-color: #767676
