@import '../../../global/style/variables';
.article-meta-data {
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: $spacing;
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: $spacing;
    border-bottom: 1px solid $color_alto;
  }
  &:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
  &:last-child {
    &:after {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &--new {
    margin-left: -$spacing;
    margin-right: -$spacing;
    padding-left: $spacing;
    padding-right: $spacing;
    background-color: rgba(20, 93, 138, 0.1);
    &:first-child {
      padding-top: $spacing;
    }
    &:last-child {
      margin-bottom: -$spacing;
      padding-bottom: $spacing;
    }
  }
  &__info-item-title {
    font: var(--sub-title);
    padding-top: $spacing / 2;
  }
  &__info-item-text {
    padding-top: $spacing / 2;
    word-break: break-word;
  }
  &__data {
    display: flex;
  }
  &__header-date {
    display: flex;
    flex-direction: row;
    font: var(--strong-fine-print);
    margin-right: $spacing / 2;
  }
}
