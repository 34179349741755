@import "../../global/style/variables.scss"


.incidentsContent-wrapper 
    text-align: center

.incidentsContent-iframe
    height: 700px
    width: 100%


.incidentsContent-info-wrapper 
    margin-top: $spacing
    
.incidentsContent-info
    > *
        &:nth-child(2n)
            margin-top: $spacing / 2
            margin-bottom: $spacing
        &:first-child
            margin-top: $spacing
        &:last-child
            margin-bottom: 0px
.incidentsContent-info-fineprint
    font: var(--fine-print)
     