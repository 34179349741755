@import "../../global/style/variables.scss"

.header
  position: relative
  max-width: 1070px
  min-height: 50px
  margin: 0 auto

.header.desktop
  left: -8px

.header.phone
  display: none
  visibility: hidden

.header-logo
  display: inline-flex
  margin-left: 10px
  margin-top: 4.5px
  width: auto
  height: 41px
  background-color: var(--white-color)
  padding: 0px 10px
  z-index: 1000
  svg, svg > g
    fill: currentColor
  &:hover
    cursor: pointer

header.home-page-header
  background-color: transparent !important
  box-shadow: none !important
  width: 100%
  height: 125px
  z-index: 3
  position: relative

.home-page-header-links
  position: relative
  max-width: 1070px
  margin: 0 auto
  display: block
  padding: 0
  text-align: left
  left: -8px

.home-page-header-tenant-switch-button
  align-self: center
  min-width: 120px

.home-page-header-logout-button
  min-width: 90px
  display: flex
  align-items: center
  justify-content: center
  text-align: right
  height: 51px
  float: right
  &:hover
    cursor: pointer

.secondary_logo
  display: inline-flex
  position: absolute
  top: 1.035em
  left: 100px
  width: auto
  height: 3em
  background-color: var(--white-color)
  padding: 0 10px
  z-index: 1000
  margin-left: 20px

.home-page-header-report-disorder
  background: var(--home-page-sublink-color)
  margin: 0
  color: var(--white-color)
  cursor: pointer
  position: absolute !important
  left: calc(100% - 161px)
  padding: 0 25px 0 25px

  div:nth-child(2)
    width: 180px
    font-family: var(--secondary-font), var(--backup-font)
    font-weight: 200
    margin-top: 2px

.home-page-header-top-section-mobile
  display: flex
  flex: 1
  flex-direction: row
  height: 50px
  justify-content: space-between

.home-page-header-logo-mobile-wrapper
  flex: 1
  height: 100%
  display: flex
  align-items: center
  margin-left: $spacing / 2

.home-page-header-logo-mobile
  display: block
  max-height: 41px
  width: auto
  height: auto

.home-page-header-logout-button-mobile
  display: flex
  align-items: center
  justify-content: flex-end
  margin-right: $spacing
  text-align: right
  height: 41px
  &:hover
    cursor: pointer

@media (min-width: 220px) and (max-width: 1090px)
  .header.desktop, .home-page-header-links
    display: none
    visibility: hidden

  .header.phone
    display: block
    visibility: visible

  .home-page-header-logout-button
    float: unset

  header.home-page-header
    background-color: var(--white-color) !important
    height: auto

  .header-mobile-links
    display: block
    width: 100%
    background: var(--white-color)
    z-index: 1000
    position: relative
    height: 54px
    top: 0

  .home-page-header-report-disorder
    float: right

    div:nth-child(2)
      width: auto
