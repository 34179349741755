@import '../../global/style/variables';
.message-box {
  @include breakpoint($tablet-and-up) {
    // margin-top: 50px;
  }
  &__outer-box {
    padding: 30px 26px;
  }

  &__custom-button {
    margin: 10px 0 0 0 !important;
    padding: 0 20px;
  }
}
