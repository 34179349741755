@import "../../global/style/variables.scss"

.input-row
    width: 100%
    &__long
        display: flex
        flex-wrap: wrap
        gap: $spacing
    &__short
        display: flex
        gap: $spacing
        flex-wrap: wrap

    & > *
        flex-basis: calc(33% - $spacing)
        min-width: 180px
@media (max-width: $bp-tablet)
    .input-row
        & > *
            flex-basis: auto
            width: 100%
            min-width: 180px
