@import '../../global/style/variables'

.document-download
    max-width: 1070px
    margin: 0 auto $spacing
    padding: 0 $spacing
    a
        color: var(--link-color)
        cursor: pointer
    &__selection-wrapper
        margin-bottom: $spacing / 2

    &__download-wrapper
        margin: $spacing 0

    &__download-section-wrapper
        display: grid
        grid-template-columns: repeat(2, minmax(150px, 1fr))
        gap: $spacing / 2 0
        justify-content: center
        align-items: center
        > .no-file-info
            grid-column: span 2 / span 2

@media(max-width: $bp-small-tablet)
    .document-download

        &__download-section-wrapper
            grid-template-columns: repeat(1, minmax(0, 1fr))
            grid-template-rows: repeat(2, minmax(0, 1fr))
