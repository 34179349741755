@import '../../global/style/variables';

.outer-box {
  display: block;
  height: auto;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: $spacing;
  margin: 0 -25px;
  margin-bottom: $spacing;

  @include breakpoint($small-tablet-and-up) {
    margin: 0 0 $spacing;
  }

  &--mt-spacing {
    margin-top: $spacing;
  }

  &--p-spacing {
    padding: $spacing;
  }
}
