.pdfs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__download {
    font-family: var(--secondary-font), var(--backup-font);
    font-size: 20px;
    color: var(--primary-color);
    font-weight: normal;
    margin: 0;
  }
  &__links {
    font-family: var(--secondary-font), var(--backup-font);
    color: var(--grey-color);
    text-decoration: underline;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5px;
    font-style: normal;
  }
  &__image {
    margin-right: 5px;
  }
}
