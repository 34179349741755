//fonts
$font_0: monospace

//@extend-elements
//original selectors
//sub, sup
%extend_1 
  font-size: 75%
  line-height: 0
  position: relative
  vertical-align: baseline


//original selectors
//button, input, optgroup, select, textarea
%extend_2 
  font-family: inherit
  font-size: 100%
  line-height: 1.15
  margin: 0


html 
  line-height: 1.15
  -webkit-text-size-adjust: 100%

body 
  margin: 0

main 
  display: block

h1 
  font-size: 2em
  margin: 0.67em 0

hr 

  box-sizing: content-box
  height: 0
  overflow: visible

pre 
  font-family: $font_0, $font_0
  font-size: 1em

a 
  background-color: transparent

abbr[title] 
  border-bottom: none
  text-decoration: underline dotted

b 
  font-weight: bolder

strong 
  font-weight: bolder

code 
  font-family: $font_0, $font_0
  font-size: 1em

kbd 
  font-family: $font_0, $font_0
  font-size: 1em

samp 
  font-family: $font_0, $font_0
  font-size: 1em

small 
  font-size: 80%

sub 
  @extend %extend_1
  bottom: -0.25em

sup 
  @extend %extend_1
  top: -0.5em

img 
  border-style: none

button 
  @extend %extend_2
  overflow: visible
  text-transform: none
  -webkit-appearance: button
  &:-moz-focusring 
    outline: 1px dotted


input 
  @extend %extend_2
  overflow: visible

optgroup 
  @extend %extend_2

select 
  @extend %extend_2
  text-transform: none

textarea 
  @extend %extend_2
  overflow: auto

[type="button"] 
  -webkit-appearance: button
  &:-moz-focusring 
    outline: 1px dotted


[type="reset"] 
  -webkit-appearance: button
  &:-moz-focusring 
    outline: 1px dotted


[type="submit"] 
  -webkit-appearance: button
  &:-moz-focusring 
    outline: 1px dotted


button::-moz-focus-inner 
  border-style: none
  padding: 0

[type="button"]::-moz-focus-inner 
  border-style: none
  padding: 0

[type="reset"]::-moz-focus-inner 
  border-style: none
  padding: 0

[type="submit"]::-moz-focus-inner 
  border-style: none
  padding: 0

fieldset 
  padding: 0.35em 0.75em 0.625em

legend 

  box-sizing: border-box
  color: inherit
  display: table
  max-width: 100%
  padding: 0
  white-space: normal

progress 
  vertical-align: baseline

[type="checkbox"] 

  box-sizing: border-box
  padding: 0

[type="radio"] 

  box-sizing: border-box
  padding: 0

[type="number"]::-webkit-inner-spin-button 
  height: auto

[type="number"]::-webkit-outer-spin-button 
  height: auto

[type="search"] 
  -webkit-appearance: textfield
  outline-offset: -2px

[type="search"]::-webkit-search-decoration 
  -webkit-appearance: none

::-webkit-file-upload-button 
  -webkit-appearance: button
  font: inherit

details 
  display: block

summary 
  display: list-item

template 
  display: none

[hidden] 
  display: none