@import '../../global/style/variables';

.subscribe-box {
  a {
    color: var(--link-color);
    text-decoration: underline;
  }

  &__toggle-wrapper {
    display: flex;
    flex-direction: row;
    gap: $spacing / 2;
    margin-top: $spacing / 2;
  }
}
