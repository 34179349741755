@import "../../global/style/variables.scss"

.public-header
    &__container
        display: flex
        flex-direction: column
        height: $spacing * 4
        justify-content: center
        padding: 0 $spacing
        max-width: 1070px
        margin: 0 auto
    &__border
        height: 1px
        background-color: var(--mercury-color)
    &__logo
        img
            max-height: 40px
            max-width: 100%
            height: auto
            width: auto
