@import '../../global/style/variables.scss'

.success-popup
    padding: $spacing 0
    display: flex
    flex-direction: column
    gap: $spacing
    div
        font: var(--regular-text)
.error-popup
    padding: $spacing 0
    display: flex
    flex-direction: column
    gap: $spacing
    div
        font: var(--regular-text)
        color: var(--error-color)
