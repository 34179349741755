@import '../../../global/style/variables';
.like-card {
  display: flex;
  flex-direction: column;
  &__like-container {
    display: flex;
    align-items: center;
    margin-top: $spacing / 2;
    flex-direction: row;
  }
  &__icon {
    cursor: pointer;
  }
  &__text {
    font: var(--fine-print);
    padding-left: $spacing / 2;
  }
  &__thank-you-text {
    margin-top: $spacing;
    font: var(--fine-print);
  }
  &__question {
    margin-top: $spacing;
    font: var(--special-text-tertiary);
  }
  &__headline {
    margin-bottom: $spacing / 2;
  }
  &__paragraph {
    font: var(--regular-text);
    margin-bottom: $spacing / 2;
  }
  &__modal-content-wrapper {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
  &__disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
  &__button-spacer {
    width: $spacing / 2;
  }
  &__button-wrapper {
    margin-top: $spacing / 2;
    display: flex;
    flex-direction: row;
  }
}
