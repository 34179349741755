@import "../../../global/style/variables.scss"

.tenant-switch-modal
    &__container
        margin-top: $spacing
    &__sub-text
        text-align: left
        margin-top: $spacing
        margin-bottom: $spacing
    &__tenant-button-container
        display: flex
        flex-direction: column
        margin-top: $spacing
        margin-bottom: $spacing
        gap: $spacing
    &__tenant-button-image
        width: auto
        height: 100%
    &__tenant-button
        height: 40px
        padding: $spacing/4
        &:hover
            background-color: rgba(216,216,216, 0.5)
            cursor: pointer
    &__tenant-button-active
        background-color: rgba(216,216,216, 0.3)
    &__link-list
        display: grid
        grid-template-columns: 1fr 1fr
        grid-auto-rows: 50px
        column-gap: $spacing
        margin-bottom: $spacing
    &__link-item-container
        display: flex
        flex-direction: row
        width: 100%
        align-items: center
    &__link-item-icon
        margin-right: $spacing / 2
        width: 10px
        height: 10px
    &__link-item
        flex: 1
        text-align: left
        color: var(--grey-color) !important
        &:hover
            text-decoration: underline
            cursor: pointer

@media (min-width: 220px) and (max-width: 1090px)
    .tenant-switch-modal
        &__tenant-button-container
            flex-direction: column
        &__tenant-button
            width: 100%
            margin: 0
        &__link-list
            grid-template-columns: 1fr
