@import '../../global/style/variables';
%defaults {
  color: var(--primary-color);
  text-decoration: underline;
}

.link {
  @extend %defaults;
  &:link,
  &:visited {
    @extend %defaults;
  }
  &:hover {
    text-decoration: none;
  }
}
