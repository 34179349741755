
.modal
  display: block
  max-width: min(100vw, 660px)
  width: calc(100% - 60px)
  margin: 0 auto
  position: absolute
  top: calc(40% - 279px)
  left: calc(50% - 333px)
  background: var(--white-color)

  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .15)
  z-index: 1000
  .modal-body
    width: 100%
    h2
      font-family: var(--primary-font), var(--backup-font)
      font-size: 38px
      font-weight: normal
      font-style: normal
      font-stretch: normal
      line-height: 1.32
      letter-spacing: normal
      text-align: center
      color: var(--primary-color)

    h4
      font-family: var(--primary-font), var(--backup-font)
      font-size: 18px
      font-weight: normal
      font-style: normal
      font-stretch: normal
      line-height: 1.44
      letter-spacing: normal
      color: var(--grey-color)
      text-align: center

  form
    color: var(--primary-color)
    font-family: var(--secondary-font), var(--backup-font)
    padding: 0
    margin: 0 auto

  div
    color: var(--primary-color)
    font-family: var(--secondary-font), var(--backup-font)
    button
      margin: 0 10px

.custom-modal
  display: flex
  flex-direction: column
  align-items: center

  &__title
    margin: 0
    text-align: center
    font: var(--sub-title)

  &__content
    text-align: center
    font: var(--regular-text)

  &__btn
    flex-direction: row
