@import "../../global/style/variables.scss"

.ne-form
    display: flex
    flex-direction: column
    justify-content: space-between
    &__container
        width: 100%
        display: flex
        flex-direction: column
        padding: $spacing * 2 $spacing
        flex: 1
    &__action-buttons
        padding: $spacing 0
    &__error-text
        color: var(--error-color)
    &__upload-restriction-error
        margin-bottom: $spacing / 2
