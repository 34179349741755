@import '../../global/style/variables';

#demo-simple-select-label {
  width: max-content;
}

.pagination {
  path {
    fill: inherit !important;
  }
  display: flex;
  align-items: center;
  margin: 30px;
  justify-content: center;
  @include breakpoint($phone-and-up) {
    flex-direction: column;
    .pagination__icons {
      display: flex;
      justify-content: center;
      width: -webkit-fill-available;
    }
    .pagination__icon__last {
      padding-right: 12px;
    }
    .pagination__form {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  @include breakpoint($small-tablet-and-up) {
    margin-right: 0;
    margin-left: 0;
    flex-direction: row;
    .pagination__icons {
      display: flex;
      justify-content: flex-start;
      width: -webkit-fill-available;
    }
    .pagination__icon__last {
      padding-right: 12px;
    }
    .pagination__form {
      display: flex;
      justify-content: flex-end;
      width: 47.5%;
    }
  }
  @include breakpoint($tablet-and-up) {
    margin-right: 0;
    flex-direction: row;
    .pagination__icons {
      display: flex;
      justify-content: flex-end;
      width: -webkit-fill-available;
    }
    .pagination__icon__last {
      padding-right: 0;
    }
    .pagination__form {
      display: flex;
      justify-content: flex-end;
      width: 47.5%;
    }
  }
  &__icon {
    &__last {
      display: flex;
      align-items: center;
    }
  }
  &__text {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 15px;
    font-family: var(--secondary-font);
    font-size: 15px;
    line-height: 1.44;
    color: black;
    font-weight: 600;
  }
}
