/* define font family Polo 11 for Edis, SHNG and partly Bayernwerk */

@font-face {
  font-family: 'Polo11-Buch';
  src: url('../../assets/fonts/polo11-buch-webfont.woff2') format('woff2'),
    url('../../assets/fonts/polo11-buch-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Polo11-Halbfett';
  src: url('../../assets/fonts/polo11-halbfett-webfont.woff2') format('woff2'),
    url('../../assets/fonts/polo11-halbfett-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Polo11-Krftg';
  src: url('../../assets/fonts/polo11-krftg-webfont.woff2') format('woff2'),
    url('../../assets/fonts/polo11-krftg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Polo11-Leicht';
  src: url('../../assets/fonts/polo11-leicht-webfont.woff2') format('woff2'),
    url('../../assets/fonts/polo11-leicht-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Define font-family for Avacon */

@font-face {
  font-family: 'EONBrixSansBlack';
  src: url('../../assets/fonts/EON_BrixSansWeb-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EONBrixSansBold';
  src: url('../../assets/fonts/EON_BrixSansWeb-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EONBrixSansRegular';
  src: url('../../assets/fonts/EON_BrixSansWeb-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* Define font-family for Bayernwerk */
@font-face {
  font-family: 'EONRegularFont';
  src: url('../../assets/fonts/eon-regular_font-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
