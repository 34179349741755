@import '../../global/style/variables';

$width: calc(100% - $spacing);
$font_family_1: var(--primary-font), var(--backup-font);
$font_family_2: var(--secondary-font), var(--backup-font);
$primary-color: var(--primary-color);

.incident-utils-no-incident-wrapper {
  padding-bottom: $spacing;
  > div {
    font: var(--special-text-tertiary);
  }
}

.disorder-page-content {
  max-width: 1070px;
  margin: auto;
  padding: 0 $spacing;
}

.disorder-page-content-stage {
  .form-input-wrapper {
    width: 100%;
    margin-bottom: 20px;
    @include breakpoint($small-tablet-and-up) {
      width: 336px;
    }
  }
  .form-input-label {
    text-transform: none;
  }
}

.disorder-page-content-stage {
  width: 100%;
  position: relative;
  margin-bottom: $spacing;
  .disorder-panel {
    padding: $spacing;
    padding-bottom: 0px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
    text-align: center;
  }
  table {
    width: 100%;
    text-align: left;
    position: relative;
    thead {
      tr {
        font-family: $font_family_1;
        color: $primary-color;
        height: 67px;
        box-shadow: inset 0 -1px 0 0 #9b9b9b;

        td {
          .disorder-tabel-col-subject {
            position: relative;
            top: 0;
            left: 0;
          }
        }
      }
    }
    tbody {
      tr {
        height: 67px;
        box-shadow: inset 0 -1px 0 0 #d7d7d7;
        &:last-child {
          box-shadow: unset;
        }
      }
    }
  }
}

.disorder-page-report-incident-card-wrapper {
  margin-bottom: $spacing;
}

@media (min-width: 220px) and (max-width: 767px) {
  .disorder-list-phone-tablet {
    .disorder-list-phone-tablet-row {
      display: flex;
      flex-direction: row;
      margin-top: $spacing;
      margin-bottom: $spacing;
      align-items: center;
    }
    :last-child {
      margin-bottom: 0px;
    }
    .disorder-list-phone-tablet-tr {
      font-family: $font_family_1;
      color: $primary-color;
      font-size: 18px;
      width: 100px;
      text-align: left;
    }
    .disorder-list-phone-tablet-td {
      width: calc(100% - 100px);
      position: relative;
      text-align: left;
    }
  }
  .disorder-panel .search-input.plz-search {
    width: 336px !important;
    padding: 0 !important;
    margin: 0 auto !important;
  }
  .disorder-page-report-incident-card-wrapper {
    display: flex;
    justify-content: center;
  }
}
