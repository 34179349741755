@import '../../../../../global/style/variables.scss';

.regionDetails {
  .regionDetailsItem {
    margin-bottom: $spacing;

    .regionImageContainer {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .dataItem-container {
    display: flex;
    flex-direction: column;
    gap: $spacing/5;
  }
}
