@import "../../global/style/variables.scss"

.section-mid-title
    display: flex
    color: var(--primary-color)
    margin-top: $spacing / 2
    margin-bad: $spacing / 2
    flex-direction: row
    align-items: center
    gap: $spacing / 4
    font: var(--special-text)
