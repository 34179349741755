@import '~breakpoint-sass';

$bp-small-tablet: 570px;
$bp-tablet: 768px;
$bp-desktop: 1070px;
$bp-phone: 320px;

$form-popup-width: min(800px, calc(100vw - 2px));

$small-tablet-and-up: min-width $bp-small-tablet;
$tablet-and-up: min-width $bp-tablet;
$desktop-and-up: min-width $bp-desktop;
$phone-and-up: min-width $bp-phone;
$tablet-and-down: max-width $bp-tablet;

$polo11-krftg: Polo11-Krftg;
$sans-serif: sans-serif;
$polo11-buch: Polo11-Buch;
$polo11-leicht: Polo11-Leicht;

$color_matisse_approx: #145f8c;
$chateau_green: #3eb14d;
$color_boulder: #767676;
$color_alto: #d8d8d8;
$color_sunset_orange: #f64c47;
$color_orange: #fbb900;
$color_electric_green: #86bc25;

$card-width: 330px;
$color_chateau_green: #3eb14d;
$color_black: #000;
$color_white: #fff;
$color_dusty_gray: #9b9b9b;
$color_mercury: #e8e8e8;

$spacing: 20px;
