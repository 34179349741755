@import '../../global/style/variables.scss';

$font_family_1: var(--primary-font), var(--backup-font);
$font_family_2: var(--secondary-font), var(--backup-font);
$color_1: var(--primary-color);

.incidents-page {
  max-width: 1070px;
  margin: auto;
  padding-left: $spacing;
  padding-right: $spacing;
  margin-bottom: $spacing;
}

.incidents-stage-content {
  padding: $spacing;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
}

.incidents_text {
  text-align: right;
  width: 95%;
  margin: auto;
  font: var(--fine-print);
  margin-top: $spacing / 2;
}

@media (max-width: 550px) {
}
