@import "../../global/style/variables.scss"

.page-title-section
    margin-bottom: $spacing
    &__framed
        max-width: 1070px
        margin: 0 auto $spacing
        padding: 0 $spacing
    .page-title-section-subtext-wrapper
        margin-top: $spacing/2
        