@import "../../global/style/variables.scss"
    
.separator
    width: 51px
    height: 4px
    content: ''
    display: block
    margin-top: $spacing
    margin-bottom: $spacing

.primary-color
    background-color: var(--primary-color)

.secondary-color
    background-color: var(--secondary-color)

.white-color
     background-color: var(--white-color)

.homepage-color
    background-color: var(--home-page-color)