@import "../../global/style/variables.scss"

//colors
$color_boulder_approx: var(--grey-color)
$color_matisse_approx: var(--primary-color)

.bread-crumb-container
  width: auto
  text-align: left
  margin: 0
  padding: 0 $spacing
  position: relative
  top: 0
  left: 0
  .bread-crumb-list
    list-style: none
    display: flex
    flex-wrap: wrap
    margin: 8px 0
    padding: 0
    .bread-crumb-list-element
      margin: 0
      &:last-child
        margin-top: 1px
        .chevron
          display: none

      > a
        font-family: var(--tertiary-font), var(--backup-font)
        font-size: 14px
        color: $color_boulder_approx
        display: flex
        align-items: center
        position: relative
        padding-right: 9px
        margin-right: 9px

        > span.lines
          width: 6px
          height: 1px
          background: $color_boulder_approx
          border-radius: 1px
          display: block
          pointer-events: none
          position: relative
          left: 8px
          top: 8px
          &:nth-child(1)
            transform: translateY(-1px) rotate(45deg)

          &:nth-child(2)
            width: 6px
            transform: translateY(4px) translateX(-6px) rotate(-45deg)

      &:nth-last-child(1) > a
        font-family: var(--primary-font), var(--backup-font)
        font-size: 14px
        font-weight: normal
        color: $color_matisse_approx
        > span.lines
          display: none

.email-icon
  display: flex
  span.lines
    width: 18px
    height: 1px
    background: $color_boulder_approx
    &:nth-child(1)
      width: 12px
      transform: rotate(90deg)

    &:nth-child(2)
      transform: translateY(6px) translateX(-6px)

    &:nth-child(3)
      width: 12px
      transform: translateX(-12px) rotate(90deg)

    &:nth-child(4)
      transform: translateX(-36px) translateY(-6px)

    &:nth-child(5)
      width: 10px
      transform: translateX(-46px) translateY(-2px) rotate(135deg)

    &:nth-child(6)
      width: 10px
      transform: translateX(-64px) translateY(-2px) rotate(-135deg)

    &:nth-child(7)
      width: 6px
      transform: translateX(-73px) translateY(2px) rotate(135deg)

    &:nth-child(8)
      width: 6px
      transform: translateX(-69px) translateY(2px) rotate(-135deg)
