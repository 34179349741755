@import '../../../global/style/variables.scss';

.supportHotlineComponent {
  .scheduleItem {
    display: flex;

    .scheduleKey {
      min-width: 80px;
    }
  }

  .contactPhoneNumber {
    color: var(--primary-color);
    font: var(--special-text);
    margin-bottom: $spacing;
  }

  .schedule-wrapper {
    margin-bottom: $spacing;
  }
}
