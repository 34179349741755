@import '../../../global/style/variables';
.event-meta-data {
  // I do not like it but it seems that it must be so directly declared
  path {
    fill: gray;
  }
  &__header {
    display: flex;
    flex-direction: row;
  }
  &__header-date {
    font: var(--strong-fine-print);
    margin-right: $spacing / 2;
  }
  &__title {
    font: var(--sub-title);
    padding-top: $spacing / 2;
  }
  &__text {
    margin: $spacing / 2 0 $spacing 0;
    word-break: break-word;
  }
  &__info {
    display: flex;
    margin-top: $spacing / 2;
  }
  &__info-item {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  &__info-item-section {
    display: flex;
    flex-direction: row;
    &--vcenter {
      align-items: center;
    }
  }
  &__info-icon-container {
    width: 20px;
  }
  &__info-icon {
    fill: gray;
    color: red;
  }
  &__info-item-text {
    font: var(--event-list-group-special-text);

    word-break: break-word;
    &--space-left {
      margin: 0 0 0 7px;
    }
    &--space-top:first-child {
      margin-top: 0;
    }
    &--space-top {
      margin-top: $spacing / 2;
    }
  }
  &__info-item-link {
    cursor: pointer;
    font: var(--event-list-group-special-text);
    margin-left: 7px;
    margin-bottom: -$spacing;
    color: var(--link-color) !important;
    text-decoration: underline !important;
  }
}
