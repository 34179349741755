@import '../../global/style/variables.scss';

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font: var(--regular-text);

  &__input-container {
    width: 80%;
  }

  .MuiInputLabel-root {
    font: var(--regular-text);
  }

  .MuiInputBase-input {
    font: var(--regular-text);
  }

  .MuiFormControlLabel-label {
    font: var(--regular-text);
  }

  &__checkbox-label {
    margin: 0;
  }

  &__checkbox {
    > .Mui-checked {
      color: var(--primary-color);
    }

    > .Mui-checked + .MuiSwitch-track {
      background-color: var(--primary-color);
    }

    > .MuiSwitch-colorSecondary.Mui-checked:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  &__line {
    content: '';
    height: 1px;
    background: #d7d7d7;
    color: rgba(118, 118, 118, 1);
    width: 90%;
    margin: 30px 0 30px 0;
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-bottom: $spacing;
  }

  &__disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
  }

  &__alert {
    margin: 15px 30px;
    font: var(--regular-text);
  }
  &__alert-title {
    margin-top: 4px;
    margin-bottom: $spacing / 2;
    font: var(--special-text);
  }

  &__margin-right {
    margin-right: 8px;
  }
}
