@import "../../../../global/style/variables.scss"

.branch-selection-information
    margin-top: $spacing
    display: flex
    flex-direction: column
    gap: $spacing/2

    .ne-step-branch-selection
        display: flex
        flex-direction: column
        gap: $spacing/2
