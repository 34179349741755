@import "../../global/style/variables.scss"

.search-box-container
    margin-bottom: $spacing

.search-title    
    color: var(--quick-silver-color)

.search-tags
    margin-top: $spacing / 4

.search-tags-new
    margin-top: $spacing
    color: var(--quick-silver-color)

.search-field
    display: flex !important
    margin-top: 10px
    width: 260px
    height: auto
    background-color: rgba(255, 255, 255, 0)

.tags-box
    display: flex
    flex-direction: row
    width: 200px

.tags
    width: auto
    padding: 0 10px 0 10px
    margin: 0 5px 0 5px
    height: 20px
    background-color: #EAECEE
    border-radius: 7px
    color: var(--primary-color)

.search-box__form


.search-field__articles
    width: 260px
    margin-right: $spacing
