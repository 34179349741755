@import "../../global/style/variables.scss"

.subnav-button 
  color: var(--secondary-text-color-footer)
  text-transform: none
  border: none
  background-color: transparent
  font: var(--regular-text)
  &:hover
    cursor: pointer
    
.subnav-button-wrapper
  height: 50px
  display: flex
  justify-content: center
  margin: 0 $spacing / 2
  &:hover
    background-color: hsla(0,0%,62%,.2)
  

.footer-content
  background-color: var(--secondary-color-footer)
  display: flex
  align-items: center
  justify-content: center
  width: auto
  height: 50px

@media (min-width: 220px) and (max-width: 956px)
  .footer-content
  .subnav-button-wrapper
    display: flex
    justify-content: center
    margin: 0
