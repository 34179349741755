@import '../../global/multiThemingStyling/multiClientGlobalVariables.scss'
@import "../../global/style/variables.scss"

.sub-title
    font: var(--sub-title)
    color: var(--primary-color)
    margin-bottom: $spacing
    &__framed
        max-width: 1070px
        margin: 0 auto $spacing
        padding: 0 $spacing