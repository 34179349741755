@import "../../global/style/variables.scss"

.action-button
    color: var(--white-color)
    font: var(--button-text)
    text-transform: none
    padding: 0 $spacing / 2
    align-self: flex-start
    border-radius: 100px
    border: solid 2px var(--white-color)
    min-height: 41px
    background: var(--card-link-color)
    svg
        fill: var(--white-color)
        stroke: var(--white-color)
    > .button-content
        padding: $spacing / 2
        width: 100%
        margin: auto
        gap: $spacing / 4
        display: flex
        justify-content: space-between
        align-items: center
        > .button-title
            flex: 9
            display: flex
            justify-content: center
            align-items: center
        > .icon-space
            flex: 1
    a
        display: inline-block
    &__loading-spinner
        color: var(--disabled-button-color)
    &:hover
        color: var(--card-link-color)
        border: solid 2px var(--card-link-color)
        background: var(--white-color)
        cursor: pointer
        svg
            fill: var(--card-link-color)
            stroke: var(--card-link-color)
    &:focus-visible
        color: var(--card-link-color)
        border: solid 2px var(--card-link-color)
        background: var(--white-color)

    &:active
        color: var(--card-link-color)
        border: solid 2px var(--card-link-color)
        background: var(--action-button-active-color)

    &:disabled
        color: var(--white-color)
        border: solid 2px var(--disabled-button-color)
        background: var(--disabled-button-color)
        cursor: not-allowed

    &:visited
        color: var(--card-link-color)
        border: solid 2px var(--card-link-color)
        background: var(--white-color)

    &.inverted
        color: var(--card-link-color)
        border: solid 2px var(--card-link-color)
        background: var(--white-color)
        display: inline-block
        svg
            fill: var(--card-link-color)
            stroke: var(--card-link-color)
        a
            display: inline-block
        &:hover
            color: var(--white-color)
            border: solid 2px var(--card-link-color)
            background: var(--card-link-color)
            cursor: pointer
            svg
                fill: var(--white-color)
                stroke: var(--white-color)
        &:focus-visible
            color: var(--white-color)
            border: solid 2px var(--card-link-color)
            background: var(--card-link-color)

        &:active
            color: var(--white-color)
            border: solid 2px var(--card-link-color)
            background: var(--action-button-inverted-active-color)

        &:disabled
            color: var(--disabled-button-color)
            border: solid 2px var(--disabled-button-color)
            background: var(--white-color)
            cursor: not-allowed

        &:visited
            color: var(--white-color)
            border: solid 2px var(--card-link-color)
            background: var(--card-link-color)

@media(max-width: $bp-small-tablet)
    .action-button
        align-self: stretch
