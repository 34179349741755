@import "../../global/style/variables.scss"

.test-alert
    text-align: left
    color: black
    width: 100%
    background: $color_mercury
    padding: $spacing
    display: flex
    flex-direction: column
    gap: $spacing
.test-alert-bold
    font: var(--strong-regular-text)
