@import '../../../global/style/variables';
.download {
  &__disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.event-participants-list-button {
  margin-top: $spacing;
}

.event-register-form {
  display: block;
  margin-left: 0;

  &__booked-out-text {
    margin-top: $spacing;
  }

  &__charging-amount {
    font: var(--special-text);
    margin-bottom: $spacing;
  }

  &__modal-content-wrapper {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
  &__registration-wrapper {
    display: flex;
    flex-direction: row;
  }
  &-new-participant-meta-container {
    display: flex;
    align-items: center;
    margin-bottom: $spacing / 2;
  }
  &__email-information {
    font: var(--fine-print);
    margin-top: $spacing / 2;
  }
  &__participant-column-wrapper {
    flex: 1;
  }
  &__position {
    background-color: var(--mercury-color);
    font-family: var(--primary-font);
    font-size: 14px;
    color: var(--primary-color);
    border-radius: 100%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__seperator {
    flex-grow: 1;
    margin-left: 8px;
    height: 1px;
    background-color: #d8d8d8;
  }
  &__remove-participant-btn-container {
    margin-top: -2px;
  }
  &__remove-participant-btn {
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    font: var(--fine-print);
    color: var(--grey-color);
  }

  &-new-participant-fields {
    margin-bottom: $spacing;
  }
  &__add-participant-btn {
    display: flex;
    align-items: center;
    border: 0;
    border-radius: 100px;
    padding: 6px 10px;
    background-color: var(--mercury-color);
    font-family: var(--primary-font);
    font-size: 14px;
    color: var(--primary-color);
    cursor: pointer;
    min-width: 168px;
  }
  &__add-participant-btn-icon {
    font-family: var(--primary-font);
    margin-right: 5px;
  }
  &__mandantory-fields-description {
    font: var(--fine-print);
    margin-top: $spacing;
  }
  &__submit-button-wrapper {
    margin-top: $spacing;
  }

  &__participant-title-wrapper {
    display: flex;
    flex-direction: row;

    align-items: center;
  }
  &__participation-title {
    margin-top: $spacing;
    width: 50%;
  }
  &__participant-cancel {
    min-width: 50%;
    text-align: end;
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    &__registration-wrapper {
      flex-direction: column;
    }
  }
}
