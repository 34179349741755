@import '../../global/style/variables';
.headline {
  font: var(--sub-title);
  color: var(--primary-color);
  margin-bottom: $spacing;
  letter-spacing: normal;
  &--large {
    @include breakpoint($tablet-and-up) {
      font: var(--page-title);
    }
  }
  &--title {
    font: var(--sub-title);
    color: var(--primary-color);
    @include breakpoint($tablet-and-up) {
      font: var(--page-title);
    }
    &::after {
      width: 51px;
      height: 4px;
      content: '';
      display: block;
      margin-top: $spacing;
      background-color: var(--home-page-color);
    }
  }
}
