@import "../../global/style/variables.scss"

.download-installer-id-item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: $spacing
    width: 100%
    cursor: pointer
    &__pdf-download-img
        width: auto
        height: 40px
    &__name
        margin-top: $spacing / 2
        text-align: center
        color: var(--grey-color)

    &__download-button
        width: 50%
        background-color: var(--primary-color)
        .download-icon
            width: 32px
            height: 32px
            stroke: var(--card-link-color)
        &:hover
            .download-icon
                stroke: var(--white-color)

    &__gas
        grid-column-start: 2
        grid-row-start: 1

    &__energy
        grid-column-start: 1
        grid-row-start: 1

@media(max-width: $bp-tablet)
    .download-installer-id-item
        margin: 0
        &__gas
            grid-column-start: 1
            grid-row-start: 2

        &__download-button
            width: 100%
