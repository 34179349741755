@import "../../global/style/variables.scss"

.custom-checkbox
    display: flex
    align-items: center
    justify-content: center
    &__input
        display: flex
        align-items: center
        justify-content: center
        width: 24px
        height: 24px
        background-color: var(--white-color)
        border: 1px solid var(--primary-color)
        border-radius: 4px
        cursor: pointer
        &:hover
            background-color: var(--primary-color)
        &.selected
            background-color: var(--primary-color)
    .disabled
        cursor: not-allowed
        border: 1px solid var(--disabled-button-color)
        &:hover
            background-color: var(--white-color)

        &.selected
            background-color: var(--disabled-button-color)
    .edited
        border: 1px solid $color_orange
        &:hover
            background-color: $color_orange
        &.selected
            background-color: $color_orange
    .error
        border: 1px solid var(--error-color)
        &:hover
            background-color: var(--error-color)
        &.selected
            background-color: var(--error-color)
    &__check-icon
        visibility: hidden
        &.selected
            visibility: visible
    &:hover .custom-checkbox__check-icon
        visibility: hidden
    &:hover .disabled .custom-checkbox__check-icon
        visibility: visible
