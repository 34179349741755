@import '../../global/style/variables';

.efix-loading-indicator {
  display: flex;
  justify-content: center;

  &__svg-wrapper {
    color: var(--primary-color);
  }
}

.card-component-loading-indicator {
  width: 100% !important;
  left: 0 !important;
  height: auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  circle {
    fill: none !important;
  }

  .card-component-loading-indicator__svg-wrapper {
    left: 0 !important;
    top: 0 !important;
    margin: 0 !important;
    color: $color_white;
  }
}
