@import '../../../../global/style/variables'

.contact-person-item
  &__title
    font-size: 16px
    font-family: var(--tertiary-font)
    color: var(--contact-persons-green-color)
    font-weight: 600

  &__name
    margin-top: $spacing / 4
    font-size: 14px
    font-family: var(--tertiary-font)
    color: var(--grey-color)

  &__phone
    margin-top: 5px
    font-size: 14px
    font-family: var(--tertiary-font)
    color: var(--grey-color)
  &__phone-icon
    margin-right: 2px
  &__email
    margin-top: 5px
    font-size: 14px
    font-family: var(--tertiary-font)
    color: var(--grey-color)

  &__multi-contact-item
    display: flex
    gap: $spacing / 3
    flex-direction: column

    &__name
      margin-top: 0

    &__phone
      margin-top: 0

    &__email
      margin-top: 0
