@import '../../../global/style/variables.scss';
.table {
  display: flex;
  flex-direction: column;
  &__collection {
    width: 100%;
    &__head {
      background-color: rgba(20, 93, 138);
    }
    &__header {
      padding: $spacing / 4 0;
      font: var(--header-text);
      color: var(--white-color);
    }
    &__row {
      :nth-child(odd) {
        background-color: rgba(20, 93, 138, 0.1);
      }
    }
    &__cell {
      width: 100vh;
      word-break: break-word;
      font: var(--regular-text);
      color: var(--grey-color);
      text-align: center;
      padding: $spacing / 2 $spacing / 4;
    }
    &__absentParticipants {
      font: var(--header-text);
      color: var(--grey-color);
      padding: $spacing / 2 0;
    }
  }
}
