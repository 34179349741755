@import "../../global/style/variables.scss"

.title-wrapper
    display: flex
    flex-direction: row
    align-items: center

.title
    flex: 1
    margin-left: 45px // close button has a width of 45px
    text-align: center
    color: var(--primary-color)
    margin-bottom: 0

.no-icon 
    margin-left: 0px

.message-component-button-wrapper
    display: flex
    flex: 1
    justify-content: center
    margin-bottom: $spacing

.subtitle
    text-align: center
    align-self: center

.subtitleText
    width: 400px
    color: var(--grey-color)

.horizontalLine
    width: 538px
    height: 1px
    border: 1px var(--ford-gray-color)
    margin-left: 30px

@media (min-width: 320px) and (max-width: 480px)
    .MuiDialogContentText-root.jss20
        display: contents

@media (min-width: 481px) and ( max-width: 768px)
    .MuiDialogContentText-root.jss20
        display: contents

@media (min-width: 320px) and (max-width: 424px)
    .subtitleText
        width: auto

    .title
        width: 220px

    .horizontalLine
        margin-left: 15px
        width: calc( 100% - 30px )

@media (min-width: 425px) and (max-width: 767px)
    .title
        width: auto

    .subtitleText
        width: auto

    .horizontalLine
        margin-left: 15px
        width: calc( 100% - 30px )
