@import '../../../../global/style/variables.scss';

.it-help > i {
  bottom: 4px;
  position: relative;
  left: 6px;
}

a.links {
  color: var(--primary-color);
  font: var(--special-text);
  margin-left: $spacing / 2;
}

.links-container {
  display: flex;
  align-items: center;
}

.it-hilfe-number-container {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing;
}
