@import "../../global/style/variables"

//colors
$color_matisse_approx: var(--primary-color)
$white: var(--white-color)
$black_20: rgba(0, 0, 0, 0.2)

%extend_1
  width: $card-width
  height: $card-width
  margin: 10px

.card-content-icon
  top: 25px !important

.search-pre-selection-card
  @extend %extend_1
  background-color: $color_matisse_approx
  color: $white
  position: relative
  margin: 10px
  box-shadow: 0 2px 10px 0 $black_20
  a
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

  .teaser-title
    color: $white
    text-align: center
    padding: 0
    &:after
      width: 51px
      content: ''
      display: block
      position: absolute
      background-color: var(--pre-selection-separator-color)
      left: calc(50% - 26px)
      top: calc(50% - 1.5px)
      height: 3px

    > div
      position: absolute
      top: calc(50% - 1.5px - $spacing - 26px)
      width: 100%

  .card-teaser-list
    position: relative
    top: calc(50% + $spacing)
    width: 90%
    max-width: 255px
    left: 0
    text-align: center
    margin: 0 auto
    letter-spacing: .5px
    > div
      width: 100%
      margin: 0 auto
      left: 0
      top: 0
      > div
        width: 100%
        text-align: center
        line-height: 1.44
        top: 0px
        left: 0

    .material-icons
      display: none

  .EDI
    top: 187px

  .SHNG
    top: 187px

  .card-content-container .card-teaser-list a
    position: relative
    top: 0
    left: 0
    width: auto

  .card:nth-child(1)
    margin-left: 30px

.teaser-title
  font: var(--sub-title)
  padding: 25px

.card-content-container
  height: 100%
