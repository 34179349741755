@import "../../global/style/variables.scss"

.installer-statusbar
    &__outer-container
        margin-left: auto
        margin-right: auto
        margin-bottom: $spacing
        max-width: 1070px
        padding: 0 $spacing
    &__content-container
        padding: $spacing
        display: flex
        flex-direction: column
        background-color: var(--light-silver-color)
    &__table
        width: calc(100% - 2 * #{$spacing})
        margin-left: $spacing
        margin-right: $spacing
        th
            text-align: left
            font: var(--table-header-text)
    &__table-special
        width: unset
        margin-top: $spacing / 2
        td:first-child
            font: var(--table-header-text)
            padding-right: $spacing / 2
    &__table-row-branch
        display: flex
        align-items: center
    &__table-row-limitations
        margin-top: $spacing / 2
    &__table-row-expired
        color: var(--error-color)
    &__table-row-expired-text
        font: var(--strong-fine-print)

@media(max-width: 569px)
    .installer-statusbar
        &__table, &__table-special
            width: 100%
            text-align: center
            margin-left: unset
            margin-right: unset
            border-collapse: separate
            border-spacing: 0 $spacing
            margin-top: -$spacing * 2
            th
                display: none
            td
                margin-top: $spacing / 2
                display: flex
                flex-direction: column
                &:before
                    content: attr(data-label)
                    font: var(--table-header-text)
                &:first-child
                    padding-right: unset
        &__table-row-branch
            justify-content: center
