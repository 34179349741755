@import "../../../../global/style/variables.scss"

.ne-step-email-input
    &__email
        width: 300px
        margin-bottom: $spacing
    &__sub-text
        margin-bottom: $spacing

@media(max-width: 400px)
    .ne-step-email-input
        &__email
            width: 100%
