@import "../../global/style/variables.scss"

.form-page-wrapper
    width: 100%
    display: flex
    flex-direction: column
    justify-items: center
    align-items: center
    gap: $spacing
    margin-bottom: $spacing * 2
