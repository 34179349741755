@import "../../global/style/variables.scss"

.card-collection-my-data
    display: block
    margin: 0 auto 0px
    padding: 0
    max-width: 1070px
    &__card-grid
        justify-content: flex-start
        padding: 0px 10px
        margin-bottom: $spacing

    ._sub-title-wrapper
        padding: $spacing 0

@media(max-width: 1086px)
    .card-collection-my-data
        &__card-grid
            justify-content: center

@media(min-width: 220px) and (max-width: 569px)
    .card-collection-my-data
        button
            margin: 20px auto
