@import "../../global/style/variables.scss"

.form-section
    width: 100%
    display: flex
    flex-direction: column
    gap: $spacing / 2
    &__section-subtitle-container
        display: flex
        flex-direction: row
        align-items: center
        gap: $spacing / 4
    &__extension
        width: 100%
        display: flex
        flex-direction: column
        gap: $spacing / 2
        background: $color_electric_green
        padding: $spacing
        border-radius: 4px
        color: white !important
        > .page-title
            color: white !important
    &__title-container
        display: flex
        gap: $spacing / 4
    &__title_tooltip_icon
        height: fit-content
        display: flex
        padding: 0
        align-items: flex-start
        align-content: top
    &__subtitle_tooltip_icon
        height: fit-content
        display: flex
        padding: 0
        align-items: center
        align-content: center
    &__info-text
        white-space: pre-wrap
