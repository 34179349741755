@import "../../global/style/variables.scss"

.deb-form
    &__title-section
        width: 100%
    &__container
        width: 100%
        padding: 0 $spacing
        display: flex
        flex-direction: column
        justify-items: center
        align-items: center
    &__first-page
        margin-left: auto
        margin-right: auto
        margin-bottom: $spacing
        max-width: 1070px
        width: 100%
    &__second-page
        margin-left: auto
        margin-right: auto
        margin-bottom: $spacing
        max-width: 1070px
        width: 100%
    &__upload-restriction-error
        margin-bottom: $spacing / 2
    &__ava-tooltip-table
        >table
            padding: $spacing / 2 0
            margin: auto
            > tbody tr:nth-child(odd)
                background-color: $color_dusty_gray
                color: #fff
            th, td
                padding: $spacing / 4 $spacing / 2
                border: 1px solid #ddd
            th
                background-color: $color_boulder
                color: #fff
            table
                border-spacing: 0px
