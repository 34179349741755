@import '../../../global/style/variables';
.event-card {
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: $spacing;
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: $spacing;
    border-bottom: 1px solid $color_alto;
  }
  &:first-child {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }
  &:last-child {
    &:after {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &--new {
    margin-left: -$spacing;
    margin-right: -$spacing;
    padding-left: $spacing;
    padding-right: $spacing;
    background-color: rgba(20, 93, 138, 0.1);
    &:first-child {
      padding-top: $spacing;
    }
    &:last-child {
      margin-bottom: -$spacing;
      padding-bottom: $spacing;
    }
  }
  &__cta-container {
    margin-top: $spacing / 2;
    display: flex;
  }
  &__participants-list-button-wrapper {
    margin-left: $spacing / 2;
  }
}

.card-line {
  height: 1px;
  background-color: var(--light-silver-color);
  margin-top: $spacing;
}

.tags-box {
  display: flex;
  flex-direction: row;
}
