@import "../../global/style/variables.scss"

%extend_1
    width: $card-width
    height: $card-width

.report-incident-card-container
    @extend %extend_1
    padding: $spacing
    background-color: var(--primary-color)
    color: var(--white-color)
    align-items: center
    .report-incident-card-title-container
        align-items: center
        flex-direction: column
        display: flex
        justify-content: space-between

    .report-incident-card-container-title
        color: var(--white-color)
        margin-bottom: 0px
                
    .report-incident-card-content-container
        display: flex
        flex: 1
        align-items: center
        flex-direction: column
      
        .report-incident-card-content-item-container
            display: flex
            align-items: center
            flex-direction: column
            flex: 1
            > div
                &:first-child
                    font-family: var(--primary-font), var(--backup-font)
                
        .report-incident-card-content-fine-print
            font-size: 10px
            margin-bottom: -12px