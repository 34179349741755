@import '~breakpoint-sass';
@import '../../global/style/variables';
@import '../_shared/Layout';

.layout-two-column {
  &-inner-container {
    display: flex;
    flex-direction: column;
    padding: 0 $spacing;
    @include breakpoint($tablet-and-up) {
      flex-direction: row;
    }
  }

  &-main-content {
    width: 100%;

    @include breakpoint($tablet-and-up) {
      margin-right: $spacing;
      max-width: 666px;
    }
  }

  &-sidebar-content {
    width: 100%;
    flex: 1;
    margin-top: $spacing;
  }

  &__outer-box {
    margin-top: $spacing;
  }
}
