@import '../../global/style/variables.scss'

.home-page
  &__content
    width: 100%
    position: relative
    margin-bottom: $spacing / 2

  &__content-stage
    width: 100%
    margin: 0 auto
    padding: 0
  &__content-sites
    margin: 0 auto 0
    padding: 0
    max-width: 1070px
    justify-content: flex-start

  &__deleted-profile-text
    width: 70%
    margin: auto
    height: fit-content
    margin: $spacing auto

@media (max-width: 1090px)
  .home-page__content-sites
    justify-content: center

@media (min-width: 768px) and (max-width: 1024px)
  .home-page
    &__content-stage
      width: 100%
      max-width: 1070px
      margin: 0 auto
      padding: 0

    &__content-sites
      margin: 0
      padding: 0

    & .card:nth-child(2n + 1)
      margin-left: 20px

@media (min-width: 570px) and (max-width: 767px)
  .home-page
    &__content-sites
      margin: 0
      padding: 0

@media (min-width: 220px) and (max-width: 569px)
  .home-page
    &__content
      width: 100%
      background-clip: content-box
      background-size: 100% 402px
      bottom: 0 !important
      top: 0
    &__deleted-profile-text
      width: unset

    &-stage-text-separator
      margin-left: auto
      margin-right: auto

    &-sites
      margin: 0
      padding: 0
