
//@extend-elements
//original selectors
//.static-page-content h1, h2, h3, h4
%extend_1
  width: auto
  position: relative
  top: 0
  left: 0
  text-align: left

.hide-tablet
  display: block

.hide-phone
  display: block

.hide-desktop
  display: none

h2
  color: var(--primary-color)
  font-family: var(--primary-font), var(--backup-font)
  @extend %extend_1

h6
  color: var(--primary-color)
  font-family: var(--primary-font), var(--backup-font)

@media (min-width: 768px) and (max-width: 1024px)

  .hide-tablet
    display: block

  .hide-phone
    display: block

  .hide-desktop
    display: none

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)

  .hide-tablet
    display: block

  .hide-phone
    display: block

  .hide-desktop
    display: none

@media (min-width: 481px) and (max-width: 767px)

  .footer-content
    width: 100%
    height: 50px
    margin: 0 auto
    text-align: center
    > button
      font-size: 14px
      padding: 0 0.5em
      height: 50px
      line-height: 1.428em

  .hide-tablet
    display: none

  .hide-phone
    display: none

  .hide-desktop
    display: block

  .static-page-content
    padding: 0 0 0 30px

  .header > header
    height: 6.375em

@media (min-width: 220px) and (max-width: 480px)
  .footer-content
    width: 100%
    margin: 0 auto
    text-align: center
    > button
      text-transform: none
      width: 41%
      height: 25px
      position: relative
      text-align: left
      margin: 4px 0
      &:nth-child(1)
        left: calc(2% + 15px)

      &:nth-child(3)
        left: calc(2% + 15px)
        top: 0

      &:nth-child(4)
        width: 51%

  .footer-content
    width: auto
    height: auto
    margin: 0 auto
    padding: 10px 0
    text-align: left

  .hide-tablet
    display: none

  .hide-phone
    display: none

  .hide-desktop
    display: block

  .mdl-textfield__input
    width: 100%
    margin: 0 auto

  #city-search
    width: 100%

  .header > header
    height: 50px
