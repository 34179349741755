@import '../../global/style/variables';

$itemPadding: $spacing;
.list-loading-indicator {
  margin: $spacing 0;
}

.overview-list-item {
  cursor: pointer;
}

.list-group {
  margin-bottom: $spacing;
}

.list-group-item {
  margin-top: $itemPadding;
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: $itemPadding;
    border-bottom: 1px solid $color_alto;
  }

  &--new {
    margin-left: -$spacing;
    margin-right: -$spacing;
    margin-top: 0;
    padding-left: $spacing;
    padding-right: $spacing;
    padding-top: $itemPadding;
    background-color: rgba(20, 93, 138, 0.1);
  }
  &__meta-data {
    display: flex;
    font: var(--strong-fine-print);
  }
  &__date {
    margin-right: $spacing;
  }
  &__excerpt {
    margin-top: $spacing / 2;
  }
}
