@import '../global/style/variables.scss';

.datepicker-input-field {
  color: black;
}

.event-page__search-form {
  .MuiPickersDateRangePickerInput-root {
    margin-right: $spacing;
  }
}
