@import "../../global/style/variables.scss"

.deb-delete-company
    &__container
        width: 100%
        margin-left: auto
        margin-right: auto
        margin-bottom: $spacing
        max-width: 1070px
        padding: 0 $spacing
    &__hero
        font: var(--regular-text)
        a
            color: var(--link-color)
    &__inputs
        margin-bottom: $spacing
        margin-top: $spacing
        display: flex
        flex-direction: column
        gap: $spacing/2
        width: 60%
        min-width: 350px
    &__table
        display: flex
        flex-direction: column
        margin-top: $spacing
        &__row
            display: flex
            min-width: 500px
            width: 70%
            border-bottom: .7px solid var(--grey-color)
            &__item
                padding: $spacing / 2 0
                min-width: 200px
                &__link
                    padding: $spacing / 2 0
                    color: var(--link-color)
                    a
                        display: block
                        width: 100%
                        max-width: 100%
                        white-space: pre-wrap
                        word-wrap: break-word

@media (min-width: $bp-small-tablet) and (max-width: 900px)
    .deb-delete-company
        &__table
            &__row
                width: 100%
@media (max-width: $bp-small-tablet)
    .deb-delete-company
        &__table
            gap: $spacing / 4
            &__row
                width: 100%
                min-width: 0
                display: flex
                flex-direction: column
                gap: $spacing / 4
                padding: $spacing / 4
                &__item
                    width: 20%
                    padding: 0 $spacing / 2
                    min-width: 177px
                    &__link
                        width: 80%
                        padding: 0 $spacing / 2
                        color: var(--link-color)
