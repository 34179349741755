@import "../../global/style/variables.scss"

.asset-download-item   
    display: flex
    flex-direction: column
    align-items: center
    margin: $spacing
    max-width: 100px
    &__pdf-download-img
        width: auto
        height: 40px
    &__name
        margin-top: $spacing / 2
        text-align: center
        