@import "../../global/style/variables.scss"

.dataItem
  margin-bottom: $spacing /4
  margin-top: $spacing /4

  &.value
    font: var(--strong-regular-text)

  &.value-thin
    font: var(--regular-text)

  &.value-no-margin
    margin: 0
    font: var(--strong-regular-text)

  .tel
    font: var(--strong-regular-text)

  .name
    margin-left: 5px

  .email
    color: var(--primary-color)

  .telEmail
    display: flex
    gap: $spacing / 4

    .tel
      font: var(--strong-regular-text)

  &-email-wrapper
    font: var(--regular-text)

    // padding-top: 3px

  // media queries
  @media screen and (max-width: 575px)
    .telEmail
      flex-direction: column
      gap: $spacing / 5
