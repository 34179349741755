@import "../../global/style/variables.scss"

.form-field-wrapper
    margin: 0 $spacing / 2 $spacing / 2
    display: flex
    flex-direction: column
    max-width: 475px

@media (max-width: 1090px)
    .form-field-wrapper
        max-width: unset
