@import '../../global/style/variables';
.efix-richtext-content {
  font: var(--regular-text);
  word-break: break-word;
  padding: 0 $spacing;
  h1 {
    font: var(--page-title);
    color: var(--primary-color);
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font: var(--sub-title);
    margin-bottom: 0;
    color: var(--primary-color);
  }
  p,
  li,
  blockquote {
    font: var(--regular-text);
    margin-bottom: $spacing / 2;
  }
  ul,
  ol {
    padding-left: 5em;
  }
  a {
    color: var(--primary-color);
    text-decoration: underline;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
