@import "../../global/style/variables"
//colors
$white: var(--white-color)
$color_matisse_approx: var(--primary-color)
$color_chateau_green_approx: var(--home-page-sublink-color)

//mixins
@mixin border_bottom($direction, $width: null, $style: null, $color: null)
  border-#{$direction}: $width $style $color

@mixin padding($top: null, $right: null, $bottom: null, $left: null)
  padding: $top $right $bottom $left

// button:nth-child(3) a
//   color: #dddddd
//   cursor: not-allowed

button
  &.sublink
    position: relative
    height: 73px
    border: none
    font: var(--header-text)
    path
      fill: inherit

  &.first-level > a > div
    @include padding($top: 26px, $right: 0)
    &.active
      @include border_bottom(bottom, $width: 2px, $style: solid, $color: $color_chateau_green_approx)

    &:focus
      @include border_bottom(bottom, $width: 2px, $style: solid, $color: $color_chateau_green_approx)

    &:hover
      @include border_bottom(bottom, $width: 2px, $style: solid, $color: $color_chateau_green_approx)

.first-level
  background: transparent
  color: $color_matisse_approx
  margin-right: 35px
  left: 20px
  @include padding(0, 8px, 0, 4px)

.sublink-icon
  @include padding(0, 5px, 3px, 4px)

@media (min-width: 220px) and (max-width: 1090px)
  button
    &.sublink

    &.first-level
      > a > div
        padding: unset
        &.active
          border-bottom: none

        &:focus
          border-bottom: none

        &:hover
          border-bottom: none

      > div
        position: relative
        bottom: 7px

  .first-level
    background: $white
    color: $color_matisse_approx
    margin-left: 62px
    &:nth-child(1)
      left: 5px
      margin-right: 10px

  .sublink-icon
    @include padding(0, 5px, 3px, 4px)

  .header.phone
    button.sublink.first-level > div

    .sublink.home-page-header-report-disorder
      width: 150px
      height: 55px !important
      position: absolute
      top: 0
      left: calc(100% - 150px)

.home-page-header-links button:nth-child(1)
  margin-left: 5px
  > a > img
    display: none

.home-page-header-links .sublink .sublink__icon
  fill: currentColor
  margin-right: 5px

.homepage-header-sublink-text-wrapper
  display: flex
  gap: $spacing / 4

.sublink-red
  color: var(--error-color)
