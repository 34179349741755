@import "../../global/style/variables.scss"
.form-upload
  &__wrapper
    flex: 1
    .download-item
      color: var(--link-color)
    .info-text
      margin-bottom: $spacing / 4
  &__file-upload-section
    border: dashed $color_matisse_approx 1px
  &__warning-message-unsupported
    margin-top: $spacing / 2

.drag-drop
  background: var(--color-white)
  width: 100%
  .document-uploader
    border: 2px dashed var(--primary-color)
    padding: $spacing/2
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    position: relative
    border-radius: 8px
    cursor: pointer
    transition: scale 0.1s ease-in-out
    &.active
      border-color: $color_orange
    &.drag
      scale: 101%
      transition: scale 0.1s ease-in-out

    .upload-info
      display: flex
      align-items: center
      margin-bottom: 1rem

      .svg
        width: 16px
        height: auto
        margin-right: 1rem

      div
        p
          margin: 0
          font: var(--regular-text)

    .file-list
      display: grid
      grid-template-columns: repeat(6, minmax(0, 1fr))
      grid-template-rows: repeat(auto-fill, 90px)
      gap: $spacing
      width: 100%

    .file-item
      display: flex
      align-items: center
      padding: $spacing /2
      border: 1px solid var(--primary-color)
      border-radius: 8px
      gap: $spacing / 4
      flex-direction: column
      justify-content: center
      width: fit-content
      width: 100%

      .file-item-bottom
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        gap: 2px

        .file-info
          width: 80%
          font: var(--fine-print)
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis
          vertical-align: baseline
          margin-top: 3px
      .file-actions
        cursor: pointer
        &:hover
          svg
            color: var(--error-color)

    input[type="file"]
      display: none

@media (max-width: $bp-tablet)
  .drag-drop
    .document-uploader
      .file-list
        justify-content: center
        grid-template-columns: repeat(2, minmax(0, 1fr))
