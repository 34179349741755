@import "~breakpoint-sass"

//colors
$black: #000
$color_martini_approx: #999
$color_mine_shaft_approx: #222
$color_sail_approx: #b3d4fc
$color_silver_approx: #ccc

//@extend-elements
//original selectors
//.sr-only.focusable:active, .sr-only.focusable:focus
%extend_1
  clip: auto
  height: auto
  margin: 0
  overflow: visible
  position: static
  white-space: inherit
  width: auto

html
  color: $color_mine_shaft_approx
  font-size: 14px
  line-height: 1.4
  box-sizing: border-box
  *:focus-visible
    outline-color: var(--card-link-color)

*, *:before, *:after
  box-sizing: inherit

::-moz-selection
  background: $color_sail_approx

  text-shadow: none

::selection
  background: $color_sail_approx

  text-shadow: none

a
  text-decoration: none
  color: inherit
  &:visited
    text-decoration: none
    color: inherit

  &:link
    text-decoration: none
    color: inherit

hr
  display: block
  height: 1px
  border: 0
  border-top: 1px solid $color_silver_approx
  margin: 1em 0
  padding: 0

audio
  vertical-align: middle

canvas
  vertical-align: middle

iframe
  vertical-align: middle

img
  vertical-align: middle

svg
  vertical-align: middle

video
  vertical-align: middle

fieldset
  border: 0
  margin: 0
  padding: 0

textarea
  resize: vertical

.browserupgrade
  margin: 0.2em 0
  background: $color_silver_approx
  color: $black
  padding: 0.2em 0

.hidden
  display: none !important

.sr-only
  border: 0
  clip: rect(0, 0, 0, 0)
  height: 1px
  margin: -1px
  overflow: hidden
  padding: 0
  position: absolute
  white-space: nowrap
  width: 1px
  &.focusable
    &:active
      @extend %extend_1

    &:focus
      @extend %extend_1

.invisible
  visibility: hidden

.left
  float: left

.right
  float: right

.clearfix
  &:before
    content: " "
    display: table

  &:after
    content: " "
    display: table
    clear: both

//@media only screen and (min-width: 35em)

//@media print,(-webkit-min-device-pixel-ratio: 1.25),(min-resolution: 1.25dppx),(min-resolution: 120dpi) 

@media print
  *
    background: transparent !important
    color: $black !important
    -webkit-box-shadow: none !important

    box-shadow: none !important

    text-shadow: none !important
    &:before
      background: transparent !important
      color: $black !important
      -webkit-box-shadow: none !important

      box-shadow: none !important

      text-shadow: none !important

    &:after
      background: transparent !important
      color: $black !important
      -webkit-box-shadow: none !important

      box-shadow: none !important

      text-shadow: none !important

  a
    text-decoration: underline
    &:visited
      text-decoration: underline

  pre
    white-space: pre-wrap !important
    border: 1px solid $color_martini_approx
    page-break-inside: avoid

  blockquote
    border: 1px solid $color_martini_approx
    page-break-inside: avoid

  thead
    display: table-header-group

  tr
    page-break-inside: avoid

  img
    page-break-inside: avoid

  p
    orphans: 3
    widows: 3

  h2
    orphans: 3
    widows: 3
    page-break-after: avoid

  h3
    orphans: 3
    widows: 3
    page-break-after: avoid

  a[href]:after
    content: "(" attr(href) ")"

  abbr[title]:after
    content: "(" attr(title) ")"

  a[href^="#"]:after
    content: ""

  a[href^="javascript:"]:after
    content: ""
