@import "../../global/style/variables.scss"

.public-footer
    &__container
        display: flex
        flex-direction: row
        height: $spacing * 3
        justify-content: center
        align-items: center
        gap: $spacing
        padding: 0 $spacing
    &__link
        &:hover
            cursor: pointer

    &__border
        height: 1px
        background-color: var(--mercury-color)
