@import '../../../../global/style/variables';

.installerSupportComponent {
  .installer-support-subtext-wrapper {
    margin-bottom: $spacing;
  }
  .installerSupportSectionContactPersonContainer {
    margin-bottom: $spacing;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 10px;
  }

  .installerSupportSectionContactPersonItem {
    &:nth-child(4n) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }

    &:nth-child(4n + 2) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }

    &:nth-child(4n + 3) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }

    &:nth-child(4n + 1) {
      border-left: 2px solid rgb(242, 242, 242);
      padding-left: 5px;
    }
  }

  .borderNone {
    border: none !important;
  }

  .flex-table {
    display: flex;
    flex-direction: column;
  }

  .flex-table tbody tr {
    display: flex;
  }

  .flex-table tbody tr + tr {
    border-top: 2px solid rgb(242, 242, 242);
  }

  .flex-table tbody tr td {
    display: flex;
    flex: 1;
    padding: 0.5em;
    color: var(--contact-persons-color);
    font-size: 12px;
    font-family: var(--tertiary-font), var(--backup-font);
  }

  // responsiveness
  @media only screen and (max-width: $bp-small-tablet) {
    .installerSupportSectionContactPersonContainer {
      grid-template-columns: repeat(2, 1fr);
    }

    .installerSupportSectionContactPersonItem {
      &:nth-child(4n + 1) {
        border-left: none;
        padding-left: 0px;
      }

      &:nth-child(4n + 2) {
        border-left: none;
        padding-left: 0px;
      }

      &:nth-child(4n + 3) {
        border-left: none;
        padding-left: 0px;
      }

      &:nth-child(2n) {
        border-left: 2px solid rgb(242, 242, 242);
        padding-left: 5px;
      }
    }

    .flex-table {
      border: 0;
    }

    .flex-table tbody tr {
      flex-direction: column;
    }

    .flex-table tbody tr td {
      flex-direction: column;
    }

    .flex-table tbody tr td + td {
      border-top: none;
    }
  }
}
