.generic-info-component {
  margin-top: 60px;

  &:first-child {
    margin-top: 0;
  }

  &__link {
    font-size: 18px;
    display: inline-block;
    &:link,
    &:visited {
      margin-top: 0;
    }
  }
}
