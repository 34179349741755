@import '../../../global/style/variables.scss'

.ne-popup.popup-content
    width: $form-popup-width
    display: flex
    flex-direction: column
    padding: $spacing * 2
    gap: $spacing
    .popup-subtext
        margin-bottom: $spacing / 2
    .ne-popup-field
        margin: auto
        min-width: min(378px, 100%)
    .flex-row
        display: flex
        flex-direction: row
        justify-content: center
        flex-wrap: wrap
        gap: $spacing * 2
