@import '../../global/style/variables.scss';
.feedback-form {
  padding: 0 $spacing;
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;
  &__input-container {
    margin-top: $spacing;
    margin-bottom: $spacing;
    max-width: 286px;
  }

  @media (min-width: 220px) and (max-width: 1090px) {
    width: 100%;
    &__input-container {
      max-width: 90%;
    }
  }
}
