@import '../../global/style/variables.scss';
.event-list-group-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $spacing;
  padding-top: $spacing;
  border-bottom: 1px solid;
  border-color: var(--mercury-color);
  &:first-child {
    padding-top: 0;
  }
  @include breakpoint($small-tablet-and-up) {
    flex-direction: row;
  }
  &__modal-content-wrapper {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
  &__container {
    display: flex;
    flex-direction: column;
    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  &__info {
    display: flex;
    align-items: flex-start;
  }
  &__date {
    font: var(--event-list-group-date-text);
    margin-right: $spacing / 2;
  }
  &__participants {
    display: flex;
    font: var(--event-list-group-special-text);
    color: $color_boulder;
    &-icon {
      margin-right: 5px;
    }
  }
  &__location-icon {
    margin-right: $spacing / 3;
    width: 20px;
    height: 20px;
  }
  &__meetinglink {
    margin-left: 20px;
  }
  &__cta-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: row;
    @include breakpoint($small-tablet-and-up) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &__cta-btn {
    text-align: right;
    font: var(--regular-text);
    color: var(--grey-color);
    border: 0;
    background: none;
    text-decoration: underline;
    outline: none;
    cursor: pointer;
    &-red {
      color: var(--error-color);
      cursor: not-allowed;
      text-decoration: none;
    }
  }
  &__description {
    max-width: 412px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
  }
  &__outer-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    @include breakpoint(max-width $bp-small-tablet) {
      flex-direction: column;
    }
  }
  &__wrapper-buttons {
    align-items: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 150px;
    @include breakpoint(max-width $bp-small-tablet) {
      margin-top: $spacing;
    }
  }
  &__online-event-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__take-part-link {
    cursor: pointer;
    margin-top: 8px;
    font: var(--event-list-group-special-text);
    color: var(--link-color) !important;
    text-decoration: underline !important;
  }
  &__online-event-outer-wrapper {
    display: flex;
    flex-direction: row;
    @include breakpoint(max-width $bp-small-tablet) {
      margin-top: $spacing;
    }
  }
  &__flex-3 {
    flex: 3;
  }
  &__flex-4 {
    flex: 4;
  }
}
