@import '../../../../../global/style/variables.scss';

.businessUnitSelection {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing;

  .imageItem {
    &:hover {
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 48px;
    }
  }

  // media queries
  @media screen and (max-width: 575px) {
    .imageItem {
      img {
        width: 100%;
        height: 32px;
      }
    }
  }
}
