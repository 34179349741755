@import "../../global/style/variables.scss"

footer
  width: 100%
  background-color: var(--primary-color-footer)
  footer.subnav .footer-content button a
    color: var(--white-color)

.footer
  &__container
    display: flex
    flex-direction: row
    max-width: 1070px
    margin: auto auto $spacing * 2 auto

  &__list
    flex: 2
    padding-left: $spacing
    max-height: 144px
    list-style: none
    color: var(--primary-text-color-footer)
    margin-top: $spacing
    display: grid
    grid-template-columns: 1fr 1fr 1fr

    > button
      color: inherit
      background: none
      border: none
      outline: none
      cursor: pointer
      position: relative
      font: var(--footer-navigation-text)
      text-align: left

  &__address
    flex: 1
    display: flex
    cursor: auto
    margin-top: $spacing * 2
    margin-right: $spacing

  &__address-inner-wrapper
    margin-left: auto
    > div > p
      margin: 0
      margin-bottom: var(--footer-text-spacing)
      font: var(--footer-text)
      color: var(--primary-text-color-footer)
      text-align: left
      &:nth-child(n)
        margin-top: $spacing / 2
      &:nth-last-child(1)
        margin-top: $spacing * 1.3
        font: var(--footer-last-text)

  &__logo
    display: block
    margin-bottom: $spacing * 1.2
    width: 100%
    height: auto
    max-width: 200px
    path
      fill: inherit

    svg, svg > g
      fill: white
      max-width: 200px

@media(min-width: 0px) and(max-width: $bp-small-tablet)
  .footer
    &__container
      flex-direction: column
    &__list
      flex: 1
      max-height: unset
      display: flex
      flex-direction: column
      > button
        &:nth-child(2n)
          margin: $spacing 0
    &__address
      flex: 1
      margin-left: $spacing
    &__address-inner-wrapper
      margin-left: 0
