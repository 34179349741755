@import '../../global/style/variables.scss';

.news-page {
  &__headline {
    margin-top: 0;
  }
}

.event-page {
  &__search-form {
    display: block;
    margin: 0;
  }

  @include breakpoint($small-tablet-and-up) {
    &__search-form {
      display: flex;
    }
  }
}
