@import '../../global/style/variables.scss'
$height: 200px

.homepage-image 
    &__container
        height: $height
        width: 100%
    &__efix-image
        width: 100%
        height: $height
        object-fit: cover
    &__text-container
        position: absolute
        height: $height
        left: 0
        right: 0
        display: flex
    &__text-inner-container
        margin: auto
        max-width: 1070px
        width: 1070px
        padding-left: $spacing
    &__headline-text
        font: var(--headline-text)
        color: var(--white-color)



