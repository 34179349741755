@import '../../global/style/variables';
.tags-box {
  display: flex;
  flex-direction: row;
}

.tag {
  width: auto;
  padding: 5px 10px 5px 10px;
  background-color: #eaecee;
  border-radius: 7px;
  font: var(--strong-fine-print);
  color: $color_matisse_approx;
  margin-right: 7px;
  @include breakpoint($bp-small-tablet) {
    margin-bottom: 0;
  }
  &:first-child {
    margin-left: 0;
  }
  &--selectable {
    cursor: pointer;
  }
  &--active {
    background-color: $color_matisse_approx;
    color: $color_white;
  }
}
