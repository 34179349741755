@import "../../global/style/variables.scss"

.themed-text-field
    *
        font: var(--secondary-font)
    > p.MuiFormHelperText-root
        font: var(--fine-print)
    > label, label.Mui-focused
        font: var(--primary-font)
        color: var(--primary-color)
    > .MuiInput-underline:after
        border-bottom-color: var(--primary-color)
    input.MuiInputBase-input
        color: var(--black-color)
        &.Mui-disabled
            color: var(--disabled-button-color)
    .MuiFormLabel-root.Mui-disabled
        color: var(--disabled-button-color)
