@import "../../../global/style/variables.scss"

.ne-stepsbar
    margin: $spacing * 2 $spacing / 2 $spacing * 2 $spacing / 2
    position: relative
    &__background-line
        background-color: var(--light-silver-color)
        height: 2px
        width: 100%
    &__steps-active
        position: absolute
        display: flex
        justify-content: space-between
        left: -$spacing / 2
        right: -$spacing / 2
        top: calc(-24px / 2 + 1px)
    &__step-active
        width: 24px
        height: 24px
        border-radius: 50%
        &--active
            background-color: var(--card-link-color)
        &--number-container
            display: flex
            justify-content: center
            align-items: center
            background-color: var(--white-color)
            width: 20px
            height: 20px
            margin: 2px
            border-radius: 50%
        &--number
            text-align: center
            font: var(--steps-number)
            color: var(--card-link-color)

        .ava
            margin-bottom: 1px
        .bag
            margin-right: 1px
        &--check-container
            display: flex
            justify-content: center
            align-items: center
            width: 20px
            height: 20px
            margin: 2px
        &--check
    &__steps
        position: absolute
        display: flex
        justify-content: space-between
        left: -$spacing / 2 + 8px
        right: -$spacing / 2 + 8px
        top: -8px / 2 + 1px
    &__step
        width: 8px
        height: 8px
        border-radius: 50%
        background-color: var(--light-silver-color)

    &__lines
        position: absolute
        display: flex
        justify-content: stretch
        left: 0
        right: 0
        top: 0
    &__line
        flex: 1
        height: 2px
        &--active
            background-color: var(--card-link-color)
