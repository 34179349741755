.chevron {
  position: absolute;
  right: -2px;
  display: flex;
  align-items: center;
  top: calc(50% - 3px);
}
.chevron::before {
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: '';
  height: 6px;
  left: 0.15em;
  vertical-align: top;
  width: 6px;
  right: 0;
  transform: rotate(45deg);
}
