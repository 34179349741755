@import "../../global/style/variables.scss"

.input-field
    max-width: min(378px, 100%)
    text-align: start
    &__full-width
        max-width: 100%
    &__edited
        > label, label.Mui-focused
            color: $color_orange
        > .MuiInput-underline:before,
        > .MuiInput-underline:after
            border-bottom-color: $color_orange
    &__error
        > label, label.Mui-focused
            color: var(--error-color)
        > .MuiInput-underline:before,
        > .MuiInput-underline:after
            border-bottom-color: var(--error-color)
    &__info-text
        font: var(--fine-print)
        color: var(--primary-color)
        &__disabled
            color: var(--disabled-button-color)
    .MuiPaper-root
        width: calc($form-popup-width / 2)
        li
            width: 100%
            white-space: normal
            word-wrap: break-word
    .MuiSelect-select
        input
            width: 80%
    .MuiSelect-selectMenu
        max-width: min(378px, 100%)
        + input
            max-width: min(378px, 100%)
