@import '../../global/style/variables';
.dialog {
  display: flex;
  align-items: center;
  &__title {
    flex: 1;
    margin-bottom: 0px;
    text-align: center;
    margin-left: 45px; // close icon has a width of 45px
    color: $color_matisse_approx;
    @include breakpoint($small-tablet-and-up) {
    }
  }
  .no-icon {
    margin-left: 0px;
  }
}
