$width-1st-3rd-child: 12px
$width-5th-6th-child: 10px
$width-7th-8th-child: 6px
$bg-color: #767676

.email-icon
	display: flex
	span
		&.lines
			width: 18px
			height: 1px
			background: $bg-color
			&:nth-child(1)
				width: $width-1st-3rd-child
				transform: rotate(90deg)
			&:nth-child(2)
				transform: translateY(6px) translateX(-6px)
			&:nth-child(3)
				width: $width-1st-3rd-child
				transform: translateX(-12px) rotate(90deg)
			&:nth-child(4)
				transform: translateX(-36px) translateY(-6px)
			&:nth-child(5)
				width: $width-5th-6th-child
				transform: translateX(-46px) translateY(-2px) rotate(135deg)
			&:nth-child(6)
				width: $width-5th-6th-child
				transform: translateX(-64px) translateY(-2px) rotate(-135deg)
			&:nth-child(7)
				width: $width-7th-8th-child
				transform: translateX(-73px) translateY(2px) rotate(135deg)
			&:nth-child(8)
				width: $width-7th-8th-child
				transform: translateX(-69px) translateY(2px) rotate(-135deg)
