@import "../../global/style/variables.scss"

%extend_1
    width: $card-width
    height: $card-width
    margin: $spacing / 1.5

.homepage-card-container
    @extend %extend_1
    padding: $spacing
    background-color: var(--primary-color)
    color: var(--white-color)
    path 
        fill: inherit 
    rect, circle
        fill: var(--card-link-color) 
    .homepage-card-title-container
        font: var(--sub-title)
        align-items: center
        flex-direction: row
        display: flex
        justify-content: space-between
        text-align: left
        .homepage-card-icon-container
            display: flex
            flex-direction: row
            margin-top: -5px
            margin-bottom: -5px
            :nth-child(n)
                margin-right: $spacing
            :last-child
                margin-right: -1px 
                
    .homepage-card-content-container
        display: flex
        flex: 1
        flex-direction: column

    .homepage-card-action-button-avacon
        border: solid 2px var(--card-link-color)
        

