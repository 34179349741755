@import '../../../global/style/variables.scss'
.deb-table
  td
    font: var(--regular-text)
  th
    font: var(--table-header-text)

  .action-button-hidden
    visibility: hidden
  .action-button-fixed-width
    width: 118px

.deb-table-header
  background-color: var(--mercury-color)
  border-radius: 4px

.table-row-edited
  border-left: $color_orange solid 3px

.expert-table
  gap: $spacing / 2
  display: flex
  flex-direction: column

  &__wrapper
    display: flex
    flex-direction: column
    gap: $spacing

.table-row-deleted
  border-left: var(--grey-color) solid 3px
  background-color: var(--mercury-color)
  color: var(--grey-color)

.capital
  text-transform: capitalize

.action-buttons
  display: flex
  gap: $spacing / 4
