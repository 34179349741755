@import "../../global/style/variables.scss"

.label-checkbox
    display: flex
    align-items: center
    > div
        &.error
            color: var(--error-color)
    > :first-child
        margin-right: $spacing / 1.5
    > img
        margin-right: $spacing / 4
