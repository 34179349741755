//colors
$black_15: rgba(0, 0, 0, .15)
$white: #fff
$color_boulder_approx: #767676
$color_alto_approx: #d7d7d7
$gray: #808080
$red: #f00
$color_bright_red_approx: #b30000

.select-container 
  max-width: 346px
  height: 300px
  box-shadow: 0 5px 25px 0 $black_15
  background-color: $white
  margin: 0 auto
  &.inactive 
    height: auto
    box-shadow: none

  .mdl-textfield 
    height: 50px


.inner-select-container 
  width: 100%
  overflow-y: scroll
  overflow-x: hidden
  height: 75%

.select-option 
  max-width: 310px
  font-family: var(--secondary-font), var(--backup-font)
  font-size: 18px
  color: $color_boulder_approx
  text-align: left
  padding: 11px
  margin: 0 0 0 5px
  box-shadow: inset 0 -1px 0 0 $color_alto_approx
  cursor: pointer

.select-container::-webkit-scrollbar 
  width: 20px

.select-container::-webkit-scrollbar-track 
  box-shadow: inset 0 0 5px $gray
  border-radius: 10px

.select-container::-webkit-scrollbar-thumb 
  background: $red
  border-radius: 10px
  &:hover 
    background: $color_bright_red_approx


#city-search-label.floated 
  font-size: 12px
  top: 5px
  height: 20px