$margin60: 60px;
$margin25: 25px;
$width: 100%;

.imprint-page {
  margin-bottom: 60px;
}

.para1 {
  h5 {
    margin-top: $margin60;
    margin-bottom: $margin25;
  }
  p {
    line-height: 0.5;
  }
  a {
    margin-top: $margin25;
    margin-bottom: $margin25;
  }
}

.para2 {
  h5 {
    margin-top: $margin60;
  }
}

.para3 {
  p {
    margin-top: $margin60;
  }
}

@media (max-width: 569px) and (min-width: 220px) {
  .para1 {
    p {
      margin: 0;
      line-height: 1.4;
      width: $width;
    }

    a {
      margin: 15px 0 15px 0;
      width: $width;
    }
  }
}
