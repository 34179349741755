$color-white: var(--white-color)
$bg-color: var(--primary-color)

$width: 68px

.hamburger-menu-container
	position: absolute
	top: 0
	width: 100%

#hamburger-menu
	width: 100%
	height: 55px
	background: $color-white
	position: relative
	top: 0

label
	&.hamburg
		display: block
		position: relative
		background: $bg-color
		width: $width
		height: 55px

input#hamburg
	display: none

.line
	position: absolute
	height: 2px
	width: 20px
	left: 24px
	background: $color-white
	border-radius: 1px
	display: block
	transition: 0.5s
	transform-origin: center
	&:nth-child(1)
		top: 12px
	&:nth-child(2)
		top: 18px
	&:nth-child(3)
		top: 24px

#hamburg
	&:checked
		& + .hamburg
			.line
				&:nth-child(1)
					transform: translateY(6px) rotate(-45deg)
				&:nth-child(2)
					opacity: 0
				&:nth-child(3)
					transform: translateY(-6px) rotate(45deg)
		& ~ .hamburger-menu-list
			transform: none

.hamburger-menu-label
	width: $width
	height: 18px
	font-size: 12px
	color: $color-white
	position: absolute
	top: 37px
	text-align: center

.hamburger-menu-list
	position: absolute
	width: 100%
	top: 55px
	padding: 0
	background: $bg-color
	list-style-type: none
	-webkit-font-smoothing: antialiased
	transform-origin: 0 0
	transform: scaleY(0)
	transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0)
	color: $color-white
	button
		&.first-level
			width: calc(100% - 16px)
			margin: 10px 0 6px 16px
			height: 40px
			bottom: 0
			left: 0
			top: 5px
			text-align: left
			background: $bg-color
			color: $color-white
			border-bottom: 1px solid $color-white

			&:nth-last-child(1)
				border: none
			& > a
				& > img
					display: none

.hamburger-menu-list button.first-level a:hover, button.first-level a:focus, button.first-level a.active
	outline: none
	cursor: pointer
	opacity: 1
